import React, {useState} from 'react'
import NavBar from '../components/Navbar/NavBar'
import Cta from '../components/Cta'
import Footer from '../components/Footer'


const CareerList = () => {
    const [firstName, setFirstName] = useState();
    const [errors, setErrors] = useState([])
    const clearErrors = () => {
        setErrors([])
    }
    return (
        <>
        <div>
            <NavBar />
        </div>
        
        <div className='pt-20'>
            <section className='py-20'>            
                <div className='grid sm:grid-cols-10 lg:grid-cols-10 gap-5 mx-32 pt-8'>
                    <span>Integri Career</span>
                    <hr className='align-middle mt-3 text-black'/>
                </div>
                <div className='grid sm:grid-cols-2 lg:grid-cols-2 gap-5 mx-32 pt-8'>
                    <div className="w-auto bg-white justify-center w-5/6">
                        <h1 className='text-6xl'>Your route to the future begins here</h1>
                    </div>
                    <div className="w-auto bg-white justify-center">                    
                        <p className='py-4'>Come aboard Integri Marine to start your journey to success. Explore new frontiers to the future in a dynamic and competitive industry with our expert-led team that thrives on challenges, innovation and peer collaboration to make the journey as exciting as the destination itself.</p>
                    </div>                    
                </div>
                <div className='justify-center w-full flex pt-16'>
                    <form>
                        <div className='grid sm:grid-col-2 lg:grid-cols-2'>
                            <div className='px-2'>
                                <label>First Name</label>
                                <input 
                                    name="first_name" 
                                    className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" 
                                    placeholder="Enter Your Name*" 
                                    value={firstName}
                                    onChange={(e)=> setFirstName(e.target.value)}
                                    onKeyUp={clearErrors}
                                />
                                {errors && 
                                    <p className="text-red-500 text-sm">{errors.first_name}</p>
                                }
                            </div>
                            <div className='px-2'>
                                <label>Email</label>
                                <input 
                                    name="first_name" 
                                    className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" 
                                    placeholder="Enter Your Email" 
                                    value={firstName}
                                    onChange={(e)=> setFirstName(e.target.value)}
                                    onKeyUp={clearErrors}
                                />
                                {errors && 
                                    <p className="text-red-500 text-sm">{errors.first_name}</p>
                                }
                            </div>
                            <div className='px-2 pt-4'>
                                <label>Phone Number</label>
                                <input 
                                    name="first_name" 
                                    className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" 
                                    placeholder="Enter Your Email Id" 
                                    value={firstName}
                                    onChange={(e)=> setFirstName(e.target.value)}
                                    onKeyUp={clearErrors}
                                />
                                {errors && 
                                    <p className="text-red-500 text-sm">{errors.first_name}</p>
                                }
                            </div>
                            <div className='px-2 pt-4'>
                                <label>Working Experience</label>
                                <input 
                                    name="first_name" 
                                    className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" 
                                    placeholder="Enter Your Working Experience" 
                                    value={firstName}
                                    onChange={(e)=> setFirstName(e.target.value)}
                                    onKeyUp={clearErrors}
                                />
                                {errors && 
                                    <p className="text-red-500 text-sm">{errors.first_name}</p>
                                }
                            </div>
                        </div>
                        <div className='grid sm:grid-col-1 lg:grid-cols-1'>
                            <div>
                                <label>Description</label>
                                <input 
                                    name="first_name" 
                                    className="w-full border bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" 
                                    placeholder="Write  about yourself" 
                                    value={firstName}
                                    onChange={(e)=> setFirstName(e.target.value)}
                                    onKeyUp={clearErrors}
                                />
                                {errors && 
                                    <p className="text-red-500 text-sm">{errors.first_name}</p>
                                }
                            </div>
                            
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <button className='btn btn-primary bg-black text-white p-2'>SEND</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </section>
        </div>
        <Cta/>
        <Footer/>
        </>
    )
}

export default CareerList