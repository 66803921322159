import React from 'react';
import { HashLink } from 'react-router-hash-link';
import MenuItem from './MenuItem';

const menuItems = [
    { title:"Home",url:"/"},
    { title:"About us",url:"/integri-about"},
    { 
        title:"Services",
        url:"/integri-services",
        submenu:[
            { title:"Marine Services" , url:"/integri-services/marine-services"},
            { title:"Marine Electrical, Automation & Control Services" , url:"/integri-services/marine-electrical-automations-control-services"},
            { title:"Offshore Services" , url:"/integri-services/offshore-services"},
            { title:"Future Technology Solutions" , url:"/integri-services/future-technology-solutions"},
        ]
    },
    { title:"portfolio",url:"/",
        submenu:[
            {title:"Clientele" , url:"/clients"},
            {title:"Careers" , url:"/careers"}
        ]
    },
    { title:"contact",url:"/contact"},
]

const NavLinks = () => {
    return (
        <>
            {/* <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="#">
                Home
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/about">
                About Us
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/services">
                Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/">
                Portfolio
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" to="/contact#contact">
                Contact
            </HashLink>
            <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
                Demo our products
            </HashLink> */}
            <nav className="desktop-nav">
                <ul className="menus flex justify-between">
                    {menuItems.map((menu, index)=>{
                        return (
                            <MenuItem items={menu} key={index}/>
                        );
                    })}
                </ul>
            </nav>
        </>
    )
}

export default NavLinks;
