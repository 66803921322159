import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import ClientsList from './pages/ClientsList';
import MarineServices from './pages/MarineServices';
import Services from './pages/Services';
import About from './pages/About';
import ElectricalAutomationServices from './pages/ElectricalAutomationServices';
import FutureTechServices from './pages/FutureTechServices';
import { OffShoreService } from './components/ClientImages/ClientImages';
import OffshoreServices from './pages/OffshoreServices';
import CareerList from './pages/CareerList';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Integri Home - Integri Marine");

  return (
    <>
      <Router>
        <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/integri-about" element={<About />} />
          <Route path="/clients" element={<ClientsList />} />
          <Route path="/careers" element={<CareerList />} />
          <Route path="/integri-services" element={<Services />} />
          <Route path="/integri-services/marine-services" element={<MarineServices />} />
          <Route path="/integri-services/offshore-services" element={<OffshoreServices />} />
          <Route path="/integri-services/future-technology-solutions" element={<FutureTechServices />} />
          <Route path="/integri-services/marine-electrical-automations-control-services" element={<ElectricalAutomationServices />} />
          <Route path="/get-demo" element={<DemoProduct />} /> 
        </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
