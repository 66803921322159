import React, { useEffect, useRef, useState } from 'react';
import {Link} from 'react-router-dom';

export const titles = ["Work","Collabrate"];

const Cta = () => {
    const [seconds, setSeconds] = useState(0);
    const [work, setWork] = useState("Work");
    // useEffect(() => { if (seconds >= 20){ setSeconds(0);stopCounter();} }, [seconds])

    // const startCounter = () => interval.current = setInterval(() => {
    //     setSeconds(prevState => prevState + 1);
    //   }, 1000);

    // const stopCounter = () => clearInterval(interval.current);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds + 1);
            if(seconds == 6)setWork("Work");
            if(seconds > 6)setSeconds(0);
            if(seconds == 3)setWork("Collaborate");
        }, 1000);
        return () => clearInterval(interval);
    },[seconds]);


    return ( 
        <div className="w-full flex items-center justify-center text-white cta">
            <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12  lg:justify-between items-center">                    
                <div className="w-full flex flex-col lg:flex-row lg:justify-start">
                    <div className="mb-4">
                        <div>
                        <p className='text-4xl md:text-5xl font-bold mb-4'>Ready to </p>
                        <p className='flex text-4xl md:text-5xl font-bold mb-4'><span className='text-black px-1'>{work} </span> togather?</p>
                        </div>
                        {/* <p className="text-lg md:text-2xl">Get in touch and let us build something amazing <span className='font-black'>together!</span></p> */}
                        
                    </div>
                    
                    <div className="w-full lg:w-72 pt-6 lg:mx-12">
                        
                    </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row lg:justify-start">
                    <div className="mb-4">
                        <div className='flex justify-between'>
                        <Link to="/contact" className="bg-blue-900 border hover:bg-blue-800 hover:border-blue-800 text-white justify-center text-center rounded-lg px-10 py-3 flex items-center group">Contact Us
                        <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                        <Link to="/contact" className="bg-blue-900 border hover:bg-blue-800 hover:border-blue-800 text-white justify-center text-center rounded-lg mx-2 px-10 py-3 flex items-center group">Work With Us
                        <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Cta;